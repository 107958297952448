import { cp } from "fs";

export const getImageSizes = (maxWidth, columnWidth, gridMode, gridColumns, maxWidthPixels = 0) => {
	//console.log(maxWidth)
	//console.log(columnWidth)
	let widthPixels = 1920;
	let imageWidth;
	let log;

	switch (maxWidth) {
		case "max-w-screen-xl":
			widthPixels = 1200;
			break;
		case "max-w-prose":
			widthPixels = 1200;
			break;
		case "max-w-xs":
			widthPixels = 320
			break;
		case "max-w-sm":
			widthPixels = 384
			break;
		case "max-w-md":
			widthPixels = 448
			break;
		case "max-w-lg":
			widthPixels = 512
			break;
		case "max-w-xl":
			widthPixels = 576
			break;
		case "max-w-screen-half":
			widthPixels = 600
			break;
		case "max-w-screen-sm":
			widthPixels = 640
			break;
		case "max-w-screen-md":
			widthPixels = 768
			break;
		case "max-w-screen-lg":
			widthPixels = 1024
			break;
		case "max-w-screen-2xl":
			widthPixels = 1400
			break;
		case "max-w-screen-3xl":
			widthPixels = 2100
			break;
	}
	
	if (columnWidth === "full" || columnWidth === "fitContents") {
		if (gridMode) {
			imageWidth = Math.ceil(widthPixels / gridColumns);
		}
		else {
			imageWidth = widthPixels;
		}
		if (maxWidthPixels) {imageWidth = maxWidthPixels}
		log = `ColumnWidth: ${columnWidth}, MaxWidth: ${maxWidth}, ImageSize: ${imageWidth}px, GridMode: ${gridMode}, GridColumns: ${gridColumns}, MaxWidthPixels: ${maxWidthPixels}`
		return { imageWidth: `${imageWidth}px`, imageLog: log };
	}
	else {
		const columnDecimal = eval(columnWidth)
		imageWidth = maxWidth === "full" ? "50vw" : `${widthPixels * columnDecimal}px`
		if (maxWidthPixels) {imageWidth = `${maxWidthPixels}px`}

		log = `MaxWidth: ${maxWidth}, ImageWidth: ${imageWidth}, Decimal: ${columnDecimal}, Calculated: ${widthPixels * columnDecimal}, MaxWidthPixels: ${maxWidthPixels}`
		return { imageWidth: imageWidth, imageLog: log };
	}
	
}