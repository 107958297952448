import clsx from "clsx";
import Image from "next/image";
import ReactHtmlParser from "react-html-parser";

import HeadingTag from "@components/HeadingTag";
import getImageBlurOptions from "@lib/getImageBlurOptions";
import { widont } from "@lib/widont";

import { useAtomValue } from "jotai";
import { headerHeightAtom } from "@atoms/siteHeader";

const Hero = ({
	eyebrowHeading,
	heading,
	headingTag = "h2",
	headingSize = "lg",
	legacyBackgroundColor = "transparent",
	textColor = "currentColor",
	heroImage,
	heroImageBlur,
	mobileHeroImage,
	mobileHeroImageBlur,
	isFirstPageSection = false,
	height = "auto",
	text,
	secondaryText,
}) => {
	const targetMobileHeroImage = !!mobileHeroImage ? mobileHeroImage : heroImage;
	const headerHeight = useAtomValue(headerHeightAtom);

	return (
		<div
			className={clsx(
				"Hero",
				"flex flex-col",
				"relative",
				"w-full",
				"overflow-hidden",
				{
					"xl:h-[100vh]": height === "xl",
					"xl:h-[90vh]": height === "lg",
					"xl:h-[80vh]": height === "md",
					"xl:h-[70vh]": height === "sm",
				}
			)}
			style={{
				legacyBackgroundColor,
				color: textColor,
			}}
		>
			<div
				className={clsx("Hero__header", "relative z-20", {})}
				style={{
					paddingTop: isFirstPageSection && headerHeight ? headerHeight : 0,
				}}
			>
				{!!eyebrowHeading && (
					<div
						className={clsx("Hero__eyebrowHeading", "text-center", "text-2xl")}
					>
						{eyebrowHeading}
					</div>
				)}
				{!!heading && (
					<HeadingTag
						tag={headingTag}
						className={clsx(
							"Hero__heading",
							"max-w-5xl mx-auto",
							"text-center p-7.5",
							{
								"text-3xl md:text-4xl 3xl:text-5xl":
									!headingSize || headingSize === "xl",
								"text-3xl": headingSize === "lg",
								"text-2xl": headingSize === "md",
								"text-xl": headingSize === "sm",
							}
						)}
					>
						{widont(heading, true)}
					</HeadingTag>
				)}
			</div>

			{(!!text || !!secondaryText) && (
				<div
					className={clsx(
						"Hero__content",
						"xl:absolute z-20 xl:bottom-0",
						"px-7.5 md:px-15 py-7.5 md:py-7.5 xl:py-15",
						"flex flex-col md:flex-row xl:flex-col",
						"space-y-7.5 md:space-y-0 md:space-x-7.5 xl:space-x-0 xl:space-y-7.5"
					)}
				>
					{!!text && (
						<div
							className={clsx(
								"Hero__text",
								"text-base lg:text-xl xl:text-2xl",
								"max-w-md mx-auto"
							)}
						>
							{ReactHtmlParser(text)}
						</div>
					)}
					{!!secondaryText && (
						<div
							className={clsx(
								"Hero__secondaryText",
								"text-base lg:text-xl xl:text-2xl",
								"max-w-md mx-auto"
							)}
						>
							{ReactHtmlParser(secondaryText)}
						</div>
					)}
				</div>
			)}

			{!!heroImage && (
				<div
					className={clsx(
						"Hero__backgroundImageWrapper",
						"xl:absolute xl:inset-0 xl:min-h-[910px] z-10 max-w-screen-xl mx-auto",
						"w-full xl:h-full"
					)}
				>
					<div className={clsx("Hero__backgroundImage", "hidden xl:block")}>
						<Image
							src={heroImage.url}
							alt={heroImage.title}
							layout="fill"
							objectFit="cover"
							objectPosition="center bottom"
							{...getImageBlurOptions(heroImage, heroImageBlur)}
						/>
					</div>
					<div
						className={clsx(
							"Hero__mobileBackgroundImage",
							"block xl:hidden",
							"w-full",
							"relative"
						)}
					>
						<Image
							src={targetMobileHeroImage.url}
							width={targetMobileHeroImage.width}
							height={targetMobileHeroImage.height}
							alt={targetMobileHeroImage.title}
							layout="responsive"
							{...getImageBlurOptions(heroImage, heroImageBlur)}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default Hero;
