import clsx from "clsx";
import { A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import LogoBlock from "@components/LogoBlock";

const LogoCarousel = ({
	logos,
	legacyBackgroundColor,
	verticalPadding = "none",
}) => {
	return (
		<div className={clsx("LogoCarousel")} style={{ legacyBackgroundColor }}>
			<div
				className={clsx("LogoCarousel__content", "select-none", {
					"py-7.5": verticalPadding === "sm",
					"py-15": verticalPadding === "md",
					"py-30": verticalPadding === "lg",
					"py-60": verticalPadding === "xl",
				})}
			>
				<Swiper
					centeredSlides
					modules={[Pagination, A11y]}
					watchOverflow
					grabCursor
					a11y={{
						prevSlideMessage: "Previous slide",
						nextSlideMessage: "Next slide",
					}}
					initialSlide={logos.length / 2}
					pagination={{ clickable: true }}
					className={clsx("LogoCarousel__slides", "pb-20")}
					spaceBetween={80}
					slidesPerView={2.5}
					breakpoints={{
						768: { slidesPerView: 5 },
						1280: {
							slidesPerView: 7.5,
						},
						1536: {
							slidesPerView: 8.5,
							spaceBetween: 140,
						},
					}}
				>
					{logos.map((logo) => {
						const image = logo.image[0];

						return (
							<SwiperSlide
								key={logo.id}
								className={clsx("LogoCarousel__slide")}
							>
								<LogoBlock image={image} />
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</div>
	);
};

export default LogoCarousel;
