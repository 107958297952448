import clsx from "clsx";
import Image from "next/image";

import getImageBlurOptions from "@lib/getImageBlurOptions";
import { getImageSizes } from "../lib/getImageSizes";
import { Box, Flex } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import ClipLoader from "react-spinners/ClipLoader";

const PageImage = ({
	image,
	mobileImage,
	maxWidth,
	maxWidthPixels,
	horizontalAlignment = "center",
	columnWidth,
	containerMaxWidth,
	gridMode,
	gridColumns,
	isFirstContainer,
	iconColor,
	legacyBackgroundColor,
	roundedImage = false,
}) => {
	const { imageWidth, imageLog } = getImageSizes(
		containerMaxWidth,
		columnWidth,
		gridMode,
		gridColumns,
		maxWidthPixels
	);
	return (
		<Flex
			className={clsx("PageImage")}
			justify={horizontalAlignment}
			w="100%"
			backgroundColor={legacyBackgroundColor}
		>
			<Box
				className={clsx("PageImage__imageWrapper", "justify-center", {
					block: !mobileImage,
					"lg:flex": image?.extension === "svg" && image.size <= 10000,
					"w-full": image?.extension !== "svg" || image.size > 10000,
					"hidden md:block": !!mobileImage,
				})}
				w={image?.extension !== "svg" && "100%"}
				maxWidth={maxWidthPixels ? maxWidthPixels : maxWidth}
				textAlign={"center"}
				color={iconColor ? iconColor : null}
			>
				{!iconColor && image?.extension === "svg" && (
					<ReactSVG
						loading={() => <ClipLoader />}
						beforeInjection={(svg) => {
							svg.setAttribute("style", "max-width: 100%; height: auto;");
							svg.querySelectorAll("*").forEach((x) => {
								x.removeAttribute("font-family");
							});
						}}
						className={clsx("inline-flex")}
						src={
							process.env.NODE_ENV === "development"
								? "/servd-volumes/images/" + image.path
								: image.url
						}
					/>
				)}

				{iconColor && image.extension === "svg" && (
					<ReactSVG
						loading={() => <ClipLoader />}
						beforeInjection={(svg) => {
							svg.querySelectorAll("*").forEach((x) => {
								x.removeAttribute("font-family");
								iconColor &&
									!x.hasAttribute("stroke") &&
									x.setAttribute("fill", "currentColor");
								iconColor &&
									x.hasAttribute("stroke") &&
									x.setAttribute("stroke", "currentColor");
							});
						}}
						className={`icon-${image.id}`}
						wrapper="span"
						src={
							process.env.NODE_ENV === "development"
								? "/servd-volumes/images/" + image.path
								: image.url
						}
					/>
				)}

				{image?.extension !== "svg" && (
					<Image
						src={image.url}
						width={image.width}
						height={image.height}
						alt={image.title}
						sizes={imageWidth}
						priority={isFirstContainer ? true : false}
						className={clsx("PageImage__image", {
							"rounded-full overflow-hidden": roundedImage,
						})}
						blurDataURL={image.blur ? image.blur[0].url : null}
					/>
				)}
			</Box>

			{!!mobileImage && (
				<Box
					className={clsx("PageImage__mobileImageWrapper")}
					display={{ base: "block", md: "none" }}
				>
					{mobileImage?.extension === "svg" && (
						<ReactSVG
							loading={() => <ClipLoader />}
							beforeInjection={(svg) => {
								svg.setAttribute("style", "max-width: 100%; height: auto;");
								svg.querySelectorAll("*").forEach((x) => {
									x.removeAttribute("font-family");
								});
							}}
							wrapper="div"
							className={clsx("inline-flex")}
							src={
								process.env.NODE_ENV === "development"
									? "/servd-volumes/images/" + mobileImage.path
									: mobileImage.url
							}
						/>
					)}

					{mobileImage?.extension !== "svg" && (
						<Image
							src={mobileImage.url}
							width={mobileImage.width}
							height={image.height}
							alt={image.title}
							priority={isFirstContainer ? true : false}
							sizes={imageWidth}
							className={clsx("PageImage__image")}
							blurDataURL={mobileImage.blur ? mobileImage.blur[0].url : null}
						/>
					)}
				</Box>
			)}
		</Flex>
	);
};

export default PageImage;
