import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

import { getHref } from "@lib/routes";

const SubNavigation = ({ entries }) => {
  const router = useRouter();

  return (
    <div
      className={clsx(
        "SubNavigation",
        "max-w-screen-xl mx-auto",
        "px-7.5 lg:px-15"
      )}
    >
      <div className={clsx("SubNavigation__items", "flex flex-wrap")}>
        {entries.map((entry) => {
          const href = getHref(entry);

          const isActive = href === router.asPath;

          return (
            <Link key={entry.id} href={href}>
              <a
                href={href}
                className={clsx(
                  "SubNavigation__item",
                  "inline-flex items-center",
                  "px-4 py-2 mr-2 mb-2",
                  "border rounded-sm",
                  "transition-opacity",
                  "hover:opacity-100",
                  { "opacity-100": isActive, "opacity-25": !isActive }
                )}
              >
                {entry.title}
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SubNavigation;
