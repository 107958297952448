import clsx from "clsx";
import Image from "next/image";

import HeadingTag from "@components/HeadingTag";

const ImageGrid = ({ items, legacyBackgroundColor, columns }) => {
	return (
		<div>
			<div className={clsx("ImageGrid__items", "flex flex-col lg:flex-row")}>
				{items.map((item) => {
					const image = item.image[0];

					return (
						<div key={item.id} className={clsx("ImageGrid__item", "lg:w-1/2")}>
							<div>
								<HeadingTag
									tag="h3"
									className={clsx(
										"ImageGrid__heading",
										"text-3xl text-center",
										"mx-auto mb-4 px-7.5",
										"max-w-md"
									)}
								>
									{item.heading}
								</HeadingTag>
								<div
									className={clsx("ImageGrid__heading", "text-center", "px-15")}
									style={{ color: item.subHeadingTextColor }}
								>
									{item.subHeading}
								</div>
							</div>
							<div
								className={clsx(
									"ImageGrid__imageWrapper",
									"mt-7.5",
									"flex justify-center"
								)}
							>
								<Image
									src={image.url}
									width={image.width}
									height={image.height}
									alt={image.title}
									className={clsx("ImageGrid__image")}
								/>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ImageGrid;
