import clsx from "clsx";
import PageSections from "@components/PageSections";
import dynamic from "next/dynamic";
import PageSectionBlocks from "@components/PageSectionBlocks";
import DotBorder from "./DotBorder";

interface IProps {
	children: object;
}

const Column = ({
	children,
	columnWidth,
	constrainToMainContainer,
	blockPadding,
	gridMode = false,
	verticalAlignment = "top",
	backgroundColor,
	enableGridSizing,
	gridPosts,
	forms,
}) => {
	const columnWidthPercent =
		columnWidth !== "full" &&
		columnWidth !== "fitContents" &&
		columnWidth !== "fillSpace"
			? eval(columnWidth)
			: 0;
	const columnWidthPx = 1200 * columnWidthPercent;
	//console.log(blockPadding);

	return (
		<div
			className={clsx(
				"Column flex flex-wrap",
				{
					//"lg:inline-block": subColumn,
					"lg:w-1/5": !gridMode && columnWidth === "1/5",
					"lg:w-1/4": !gridMode && columnWidth === "1/4",
					"lg:w-1/3": !gridMode && columnWidth === "1/3",
					"lg:w-2/5": !gridMode && columnWidth === "2/5",
					"lg:w-1/2": !gridMode && columnWidth === "1/2",
					"lg:w-3/5": !gridMode && columnWidth === "3/5",
					"lg:w-2/3": !gridMode && columnWidth === "2/3",
					"lg:w-3/4": !gridMode && columnWidth === "3/4",
					"lg:w-4/5": !gridMode && columnWidth === "4/5",
					"lg:w-full": !gridMode && columnWidth === "full",
					"lg:flex-1": !gridMode && columnWidth === "fillSpace",
					"lg:shrink": !gridMode && columnWidth === "fitContents",
					"w-full": !gridMode && columnWidth !== "fitContents",
					"pt-2.5 lg:pt-7.5": blockPadding && blockPadding[0].top === "sm",
					"pt-7.5 lg:pt-15": blockPadding && blockPadding[0].top === "md",
					"pt-15 lg:pt-30": blockPadding && blockPadding[0].top === "lg",
					"pt-30 lg:pt-60": blockPadding && blockPadding[0].top === "xl",

					"pb-2.5 lg:pb-7.5": blockPadding && blockPadding[0].bottom === "sm",
					"pb-7.5 lg:pb-15": blockPadding && blockPadding[0].bottom === "md",
					"pb-15 lg:pb-30": blockPadding && blockPadding[0].bottom === "lg",
					"pb-30 lg:pb-60": blockPadding && blockPadding[0].bottom === "xl",

					"pl-2.5 lg:pl-7.5": blockPadding && blockPadding[0].left === "sm",
					"pl-7.5 lg:pl-15": blockPadding && blockPadding[0].left === "md",
					"pl-15 lg:pl-30": blockPadding && blockPadding[0].left === "lg",
					"pl-30 lg:pl-60": blockPadding && blockPadding[0].left === "xl",

					"pr-2.5 lg:pr-7.5": blockPadding && blockPadding[0].right === "sm",
					"pr-7.5 lg:pr-15": blockPadding && blockPadding[0].right === "md",
					"pr-15 lg:pr-30": blockPadding && blockPadding[0].right === "lg",
					"pr-30 lg:pr-60": blockPadding && blockPadding[0].right === "xl",
				},
				{
					"content-start": verticalAlignment == "flex-start",
					"content-center": verticalAlignment == "center",
					"content-end": verticalAlignment == "flex-end",
				},
				{
					"relative box-sizing": enableGridSizing,
				}
			)}
			style={{
				maxWidth:
					columnWidth !== "full" && constrainToMainContainer && columnWidthPx,
				backgroundColor,
			}}
		>
			{children.map((child, index) => {
				return (
					<PageSectionBlocks
						gridPosts={gridPosts}
						forms={forms}
						section={child}
						key={index}
						subColumn={child.typeHandle === "column" && true}
					/>
				);
			})}
			{enableGridSizing && <DotBorder />}
		</div>
	);
};

export default Column;
