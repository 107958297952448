import clsx from "clsx";
import Image from "next/image";
import { useState } from "react";
import ReactHtmlParser from "react-html-parser";

import PlayButton from "@components/PlayButton";
import VideoModal from "@components/VideoModal";
import getImageBlurOptions from "@lib/getImageBlurOptions";
import SecondaryButton from "@components/SecondaryButton";

const Quotation = ({
	image,
	imageBlur,
	videoUrl,
	quotation,
	attribution,
	role,
	legacyBackgroundColor,
	textColor,
	mediaPosition,
	backgroundImage,
	backgroundImageBlur,
	target,
	buttonTextColor,
	legacyButtonBackgroundColor,
	logo,
	logoBlur,
}) => {
	const [isVideoOpen, setIsVideoOpen] = useState(false);

	const handlePlayButtonClick = () => {
		setIsVideoOpen(true);
	};

	return (
		<div
			className={clsx("Quotation relative")}
			style={{ backgroundColor: legacyBackgroundColor, color: textColor }}
		>
			<div
				className={clsx(
					"Quotation__content z-10 relative",
					"flex flex-col-reverse lg:flex-row",
					"max-w-screen-xl mx-auto",
					"px-5 py-7.5",
					{
						"lg:flex-row-reverse": mediaPosition === "right",
						"lg:flex-row": mediaPosition === "left",
						"lg:py-20": !!image?.url,
						"lg:py-15": !image,
					}
				)}
			>
				{image && (
					<div className={clsx("SideBySide__media", "lg:w-1/3", "relative")}>
						{!!image?.url && (
							<Image
								key={image.id}
								className={clsx("Quotation__image")}
								src={image.url}
								width={image.width}
								height={image.height}
								alt={image.title}
								layout="responsive"
								placeholder={imageBlur ? "blur" : "empty"}
								blurDataURL={imageBlur ? imageBlur.url : null}
							/>
						)}

						<div
							className={clsx(
								"Quotation__attribution",
								"max-w-lg",
								"prose",
								"text-white-01",
								"text-2xl",
								"pt-5"
							)}
							style={{ color: textColor }}
						>
							{attribution}
						</div>
						<div
							className={clsx(
								"Quotation__attribution",
								"max-w-lg",
								"prose",
								"text-white-01"
							)}
							style={{ color: textColor }}
						>
							{role}
						</div>

						{!!logo?.url && (
							<div className={clsx("Quotation__logo max-w-[120px]")}>
								<Image
									key={logo.id}
									src={logo.url}
									width={logo.width}
									height={logo.height}
									alt={logo.title}
									layout="responsive"
									placeholder={
										logo.extension !== "svg" && logoBlur ? "blur" : "empty"
									}
									blurDataURL={logoBlur ? logoBlur.url : null}
								/>
							</div>
						)}
					</div>
				)}

				<div
					className={clsx(
						"Quotation__body",
						{ "md:pl-12 lg:w-2/3": image },
						{ "text-center mx-auto": !image },
						{
							"pl-7.5 lg:pl-15": image && mediaPosition === "right",
						}
					)}
				>
					<div className={clsx("Quotation__textContainer", {})}>
						<div
							className={clsx(
								"Quotation__quotation",
								"prose",
								"text-white-01",
								"text-2xl",
								"mb-7.5"
							)}
							style={{ color: textColor }}
						>
							{ReactHtmlParser(quotation)}
						</div>
					</div>

					{!image && (
						<div className="Quotation__details mt-7.5 max-w-sm mx-auto">
							<div
								className={clsx("Quotation__attribution")}
								style={{ color: textColor }}
							>
								{attribution}
							</div>

							{role && (
								<div
									className={clsx("Quotation__role")}
									style={{ color: textColor }}
								>
									{role}
								</div>
							)}

							{!!logo?.url && (
								<div className={clsx("Quotation__logo max-w-[120px] mx-auto")}>
									<Image
										key={logo.id}
										src={logo.url}
										width={logo.width}
										height={logo.height}
										alt={logo.title}
										layout="responsive"
										placeholder={
											logo.extension !== "svg" && logoBlur ? "blur" : "empty"
										}
										blurDataURL={logoBlur ? logoBlur.url : null}
									/>
								</div>
							)}
						</div>
					)}

					{target?.url && (
						<div
							className={clsx("sm:pt-0 pb-5 md:pb-0", {
								"md:pt-[5.5rem]": image,
							})}
						>
							<SecondaryButton
								target={target}
								legacyBackgroundColor={legacyButtonBackgroundColor}
								textColor={buttonTextColor}
							/>
						</div>
					)}
				</div>
			</div>
			{!!backgroundImage?.url && (
				<div
					className={clsx(
						"Quotation__backgroundImage",
						"h-full w-full max-w-screen-3xl m-auto inset-0 absolute"
					)}
				>
					<Image
						src={backgroundImage.url}
						layout="fill"
						objectFit="cover"
						alt={backgroundImage.title}
						draggable={false}
						{...getImageBlurOptions(backgroundImage, backgroundImageBlur)}
					/>
				</div>
			)}
			<VideoModal
				videoUrl={videoUrl}
				onClose={() => setIsVideoOpen(false)}
				isVideoOpen={isVideoOpen}
			/>
		</div>
	);
};

export default Quotation;
