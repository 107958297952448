import clsx from "clsx";
import React, { ReactNode } from "react";
import { useInView } from "react-intersection-observer";
import PageSectionBlocks from "@components/PageSectionBlocks";

interface IProps {
	section?: any;
	gridPosts?: any[];
	forms?: any[];
	isFirst?: boolean;
}

interface ISectionProps {
	children: ReactNode;
	typeHandle: string;
	legacyBackgroundColor: string;
}

const Section = ({
	children,
	typeHandle,
	legacyBackgroundColor = "transparent",
}: ISectionProps): JSX.Element => {
	const { ref, inView } = useInView();

	const className = clsx("PageSection", `PageSection--${typeHandle}`);

	return (
		<div
			ref={ref}
			className={className}
			style={{
				legacyBackgroundColor:
					typeHandle !== "defenceInDepth" && typeHandle !== "container"
						? legacyBackgroundColor
						: "transparent",
			}}
		>
			<div
				className={clsx(
					"PageSection__content",
					"transition-opacity duration-500 ease-out relative",
					{
						"opacity-0": !inView,
						"opacity-100": inView,
					}
				)}
			>
				{children}
			</div>
		</div>
	);
};

interface IProps {
	sections: any[];
	gridPosts?: any[];
	forms?: any[];
}

const PageSections = ({
	sections,
	gridPosts = null,
	forms = null,
	isPageModule = false,
}: IProps): JSX.Element => {
	return (
		<div className={clsx("PageSections")}>
			{!!sections &&
				sections.map((section, index) => {
					return (
						<div
							key={section.id}
							className={clsx(
								"PageSections__section",
								`PageSections__section--${section.typeHandle}`
							)}
						>
							<a
								className="anchor"
								id={`Section__${section.id}`}
								style={{
									display: "block",
									position: "relative",
									top: "-80px",
									visibility: "hidden",
								}}
							></a>
							<Section
								typeHandle={section.typeHandle}
								legacyBackgroundColor={section.legacyBackgroundColor}
							>
								<PageSectionBlocks
									section={section}
									isFirst={!isPageModule && index === 0}
									gridPosts={gridPosts}
									forms={forms}
								/>
							</Section>
						</div>
					);
				})}
		</div>
	);
};

export default PageSections;
