import clsx from "clsx";
import Image from "next/image";
import ReactHtmlParser from "react-html-parser";

import HeadingTag from "@components/HeadingTag";
import SecondaryButton from "@components/SecondaryButton";

const ContentList = ({
	items,
	textColor = "currentColor",
	legacyBackgroundColor = "transparent",
	headingTag = "h3",
	headingSize = "lg",
}) => {
	return (
		<div
			className={clsx("ContentList")}
			style={{ backgroundColor: legacyBackgroundColor, color: textColor }}
		>
			<div className={clsx("ContentList__items", "space-y-15")}>
				{items.map((item) => {
					const image = item.image[0];
					const imageBlur = item.imageBlur[0];

					return (
						<div
							key={item.id}
							className={clsx(
								"ContentList__item",
								"max-w-screen-xl mx-auto",
								"px-7.5 xl:px-15",
								"flex flex-col lg:flex-row lg:justify-center"
							)}
						>
							<div
								className={clsx(
									"ContentList__media",
									"lg:w-1/3",
									"md:px-7.5 mb-7.5"
								)}
							>
								<Image
									key={image.id}
									className={clsx("ContentList__image")}
									src={image.url}
									width={image.width}
									height={image.height}
									alt={image.title}
									layout="responsive"
									placeholder={imageBlur ? "blur" : "empty"}
									blurDataURL={imageBlur ? imageBlur.url : null}
								/>
							</div>

							<div
								className={clsx(
									"ContentList__body",
									"lg:w-2/3 xl:pl-7.5 xl:pr-15",
									"md:px-7.5"
								)}
							>
								<HeadingTag
									tag={headingTag}
									className={clsx("ContentList__heading", "max-w-xl mb-7.5", {
										"text-4xl 3xl:text-5xl":
											!headingSize || headingSize === "xl",
										"text-3xl": headingSize === "lg",
										"text-2xl": headingSize === "md",
										"text-xl": headingSize === "sm",
									})}
								>
									{item.heading}
								</HeadingTag>

								{!!item.text && (
									<div
										className={clsx("SideBySide__text", "prose")}
										style={{ color: textColor }}
									>
										{ReactHtmlParser(item.text)}
									</div>
								)}

								{!!item.target && !!item.target.url && (
									<div className={clsx("SideBySide__target", "mt-15")}>
										<SecondaryButton
											target={item.target}
											legacyBackgroundColor={item.legacyButtonBackgroundColor}
											textColor={item.buttonTextColor}
										/>
									</div>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ContentList;
