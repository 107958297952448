import clsx from "clsx";
import Image from "next/image";
import { useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import { useAtomValue } from "jotai";
import { headerHeightAtom } from "@atoms/siteHeader";

import SVG from "react-inlinesvg";

import EmbeddedForm from "@components/EmbeddedForm";
import HeadingTag from "@components/HeadingTag";
import Message from "@components/Message";
import PlayButton from "@components/PlayButton";
import SecondaryButton from "@components/SecondaryButton";
import VideoModal from "@components/VideoModal";
import VideoSlideDown from "@components/VideoSlideDown";
import ReactPlayer from "react-player/lazy";

import darkGrid from "@images/darkGrid.svg";
import lightGrid from "@images/lightGrid.svg";
import IImage from "@interfaces/Image.interface";
import getImageBlurOptions from "@lib/getImageBlurOptions";
import isColorDark from "@lib/isColorDark";
import { useMeasure } from "react-use";
import DotBorder from "@components/DotBorder";

interface IProps {
  legacyBackgroundColor: string;
  backgroundImage: IImage;
  mobileBackgroundImage: IImage;
  mobileBackgroundImageBlur: IImage;
  backgroundOverlay: IImage;
  backgroundImageBlur: IImage;
  legacyButtonBackgroundColor: string;
  buttonTextColor: string;
  secondaryButtonBackgroundColor: string;
  secondaryButtonTextColor: string;
  form: any;
  formDetails: any;
  formHeading: string;
  heading: string;
  headingSize: "xxl" | "xl" | "lg" | "md" | "sm";
  headingTag: "h1" | "h2" | "h3" | "h4";
  headingColor: string;
  icon: IImage;
  iconBlur: IImage;
  image: IImage;
  mediaBackgroundImage: IImage;
  mediaBackgroundImageBlur: IImage;
  imageBlur: IImage;
  indentText: boolean;
  isFirstPageSection: boolean;
  mediaPadding: "none" | "sm" | "md" | "lg" | "xl";
  mediaPaddingBottom: boolean;
  mediaPaddingTop: boolean;
  mediaPosition: "left" | "right";
  mobileImage: IImage;
  mobileImageBlur: IImage;
  mobileMediaPosition: "above" | "below";
  secondaryTarget: object;
  showBackgroundGrid: boolean;
  overrideDefaultFormValues: boolean;
  target: object;
  text: string;
  textColor: string;
  textPaddingTop: boolean;
  textPaddingMediaSide: boolean;
  centerContent: boolean;
  fullWidth: boolean;
  wideContainer: boolean;
  video?: any;
  videoUrl?: string;
  videoStyle?: "inline" | "autoplayInline" | "modal" | "slideDown";
  leftAlignPlayButton: boolean;
}

const SideBySide = ({
  legacyBackgroundColor = "transparent",
  backgroundImage,
  mobileBackgroundImage,
  mobileBackgroundImageBlur,
  backgroundOverlay,
  backgroundImageBlur,
  legacyButtonBackgroundColor,
  buttonTextColor,
  secondaryButtonBackgroundColor,
  secondaryButtonTextColor,
  formHeading,
  form,
  formDetails,
  heading,
  headingSize = "lg",
  headingTag = "h2",
  headingColor,
  icon,
  iconBlur,
  image,
  mediaBackgroundImage,
  mediaBackgroundImageBlur,
  imageBlur,
  indentText,
  isFirstPageSection = false,
  mediaPadding = "none",
  mediaPaddingBottom = false,
  mediaPaddingTop = true,
  fullWidth = false,
  wideContainer = false,
  mediaPosition = "left",
  mobileImage,
  mobileImageBlur,
  mobileMediaPosition = "above",
  secondaryTarget,
  centerContent = false,
  showBackgroundGrid = false,
  target,
  text,
  textColor,
  overrideDefaultFormValues = false,
  textPaddingTop,
  textPaddingMediaSide = true,
  videoUrl,
  videoStyle = "inline",
  leftAlignPlayButton = false,
}: IProps): JSX.Element => {
  const headerHeight = useAtomValue(headerHeightAtom);

  const [isFormSuccessfullySubmitted, setIsFormSuccessfullySubmitted] =
    useState(false);

  const targetMobileImage = !!mobileImage ? mobileImage : image;
  const targetMobileImageBlur = !!mobileImage ? mobileImageBlur : imageBlur;

  const formattedHeading = heading.replace(/(?:\r\n|\r|\n)/g, "<br>");

  const hasBackgroundColor =
    !!legacyBackgroundColor && legacyBackgroundColor !== "transparent";

  const isBackgroundColorDark = isColorDark(legacyBackgroundColor) || false;

  const handleFormSuccess = () => {
    setIsFormSuccessfullySubmitted(true);
  };

  const [isInlineVideoPlaying, setIsInlineVideoPlaying] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const slideDownContainer = useRef(null);

  const handlePlayButtonClick = () => {
    if (videoStyle === "slideDown") {
      isVideoOpen ? setIsVideoOpen(false) : setIsVideoOpen(true);
      if (!isVideoOpen) {
        const containerElement = slideDownContainer.current;
        const y =
          containerElement.getBoundingClientRect().top +
          window.pageYOffset -
          headerHeight;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    } else {
      setIsVideoOpen(true);
    }
  };

  const Media = () => {
    const formValues = form ? form.formDetails[0] : undefined;

    if (overrideDefaultFormValues) {
      Object.keys(formDetails[0]).map((key, index) => {
        if (Object.values(formDetails[0])[index]) {
          formValues[key] = Object.values(formDetails[0])[index];
        }
      });
    }

    const [ref, { x, y, width, height, top, right, bottom, left }] =
      useMeasure();

    return (
      <div
        className={clsx(
          "SideBySide__media",
          "relative",
          "lg:w-full max-w-[500px]",
          "flex",
          {
            "items-center": centerContent,
            "items-end": !centerContent,
            "pt-7.5 lg:pt-10 xl:pt-10": mediaPaddingTop,
            "pb-7.5 lg:pb-15 xl:pb-15":
              mediaPaddingBottom && !backgroundOverlay,
            "pl-0 pr-0": !mediaPadding || mediaPadding === "none",
            "lg:pl-7.5":
              !!mediaPadding &&
              mediaPadding !== "none" &&
              mediaPosition === "right",
            "lg:pr-7.5":
              !!mediaPadding &&
              mediaPadding !== "none" &&
              mediaPosition === "left",
            "pl-4 pr-4 lg:pr-4":
              mediaPosition === "right" && mediaPadding === "sm",
            "pl-4 pr-4 lg:pl-4":
              mediaPosition === "left" && mediaPadding === "sm",
            "pl-7.5 pr-7.5 lg:pr-7.5":
              mediaPosition === "right" && mediaPadding === "md",
            "pl-7.5 pr-7.5 lg:pl-7.5 ":
              mediaPosition === "left" && mediaPadding === "md",
            "pl-7.5 pr-7.5 lg:pr-15":
              mediaPosition === "right" && mediaPadding === "lg",
            "pl-7.5 pr-7.5 lg:pl-15 ":
              mediaPosition === "left" && mediaPadding === "lg",
          }
        )}
      >
        {!!image && !form && (
          <div
            className={clsx("SideBySide__imageContainer", {
              "relative z-10 w-full": !mediaBackgroundImage,
              "absolute z-10 w-full h-full flex justify-center items-center":
                !!mediaBackgroundImage,
            })}
            ref={ref}
            key={image.id}
          >
            <div
              className={clsx("SideBySide__imageWrapper", "hidden lg:block", {
                "max-w-2xl": !!mediaBackgroundImage,
              })}
            >
              {image?.extension === "svg" && (
                <SVG
                  src={
                    process.env.NODE_ENV === "development"
                      ? "/servd-volumes/images/" + image.path
                      : image.url
                  }
                  uniquifyIDs={true}
                  className={clsx("w-full h-auto")}
                  preProcessor={(code) =>
                    code
                      .replace(/font-family=".*?"/g, "")
                      .replace(
                        /fill="url/g,
                        'style="transform: translate(0.1px, 0.1px);shape-rendering: crispedges;" fill="url'
                      )
                  }
                />
              )}

              {!!image?.url && image?.extension !== "svg" && (
                <Image
                  key={image.id}
                  src={image.url}
                  width={image.width}
                  height={image.height}
                  alt={image.title}
                  layout="responsive"
                  priority={isFirstPageSection ? true : false}
                  sizes={
                    width !== undefined
                      ? `${width < 100 ? 600 : Math.round(width)}px`
                      : "100vw"
                  }
                  placeholder={
                    image.extension !== "svg" && imageBlur ? "blur" : "empty"
                  }
                  blurDataURL={imageBlur ? imageBlur.url : null}
                  className={clsx("SideBySide__image")}
                />
              )}
            </div>
            <div
              className={clsx(
                "SideBySide__mobileImageWrapper",
                "block lg:hidden",
                { "px-7.5": !!mediaBackgroundImage }
              )}
            >
              {!!targetMobileImage?.url &&
                targetMobileImage?.extension === "svg" && (
                  <SVG
                    src={
                      process.env.NODE_ENV === "development"
                        ? "/servd-volumes/images/" + targetMobileImage.path
                        : targetMobileImage.url
                    }
                    uniquifyIDs={true}
                    className={clsx("w-full h-auto")}
                    preProcessor={(code) =>
                      code
                        .replace(/font-family=".*?"/g, "")
                        .replace(
                          /fill="url/g,
                          'style="transform: translate(0.1px, 0.1px);shape-rendering: crispedges;" fill="url'
                        )
                    }
                  />
                )}
              {!!targetMobileImage?.url &&
                targetMobileImage?.extension !== "svg" && (
                  <Image
                    key={targetMobileImage.id}
                    src={targetMobileImage.url}
                    width={targetMobileImage.width}
                    height={targetMobileImage.height}
                    alt={targetMobileImage.title}
                    layout="responsive"
                    sizes={
                      width !== undefined
                        ? `${width < 100 ? 600 : Math.round(width)}px`
                        : "100vw"
                    }
                    priority={isFirstPageSection ? true : false}
                    placeholder={"blur"}
                    blurDataURL={
                      targetMobileImageBlur ? targetMobileImageBlur.url : null
                    }
                    className={clsx("SideBySide__mobileImage")}
                  />
                )}
            </div>
            {!!videoUrl &&
              (videoStyle === "modal" || videoStyle === "slideDown") && (
                <div className={clsx("SideBySide__playButton")}>
                  <PlayButton
                    onClick={handlePlayButtonClick}
                    leftAlignPlayButton={leftAlignPlayButton}
                  />
                </div>
              )}
          </div>
        )}
        {!!videoUrl && videoStyle !== "modal" && videoStyle !== "slideDown" && (
          <div
            className={clsx(
              "SideBySide__videoContainer",
              "inset-0 z-20",
              "overflow-hidden",
              "px-0 pt-[inherit]",
              "absolute"
            )}
          >
            <div className={clsx("SideBySide__videoWrapper", "w-full h-full")}>
              <ReactPlayer
                url={videoUrl}
                controls={videoStyle === "inline"}
                playing={
                  videoStyle === "autoplayInline" || isInlineVideoPlaying
                }
                playsinline
                muted={videoStyle === "autoplayInline"}
                loop={false}
                width="100%"
                height="100%"
                config={{
                  wistia: {
                    options: {
                      playerColor: "0D1418",
                      controlsVisibleOnLoad: false,
                      copyLinkAndThumbnailEnabled: false,
                      playButton: false,
                      wmode: "transparent",
                      fitStrategy: "cover",
                      chromeless: true,
                      controls: false,
                    },
                  },
                }}
                onPlay={() =>
                  videoStyle !== "autoplayInline" &&
                  setIsInlineVideoPlaying(true)
                }
                onPause={() =>
                  videoStyle !== "autoplayInline" &&
                  setIsInlineVideoPlaying(false)
                }
              />
            </div>
            {!isInlineVideoPlaying && videoStyle !== "autoplayInline" && (
              <PlayButton onClick={() => setIsInlineVideoPlaying(true)} />
            )}
          </div>
        )}
        {!!form && (
          <div
            className={clsx(
              "SideBySide__formContainer",
              "px-3.5 md:px-3.5 w-full",
              {
                "absolute z-10 w-full h-full flex flex-wrap justify-center items-center":
                  !!mediaBackgroundImage,
              }
            )}
          >
            {formHeading && (
              <h3
                className={clsx("text-2xl mb-5 w-full", {
                  "max-w-2xl": !!mediaBackgroundImage,
                })}
              >
                {formHeading}
              </h3>
            )}
            {!isFormSuccessfullySubmitted && (
              <EmbeddedForm
                form={form}
                formValues={formValues}
                onSuccess={handleFormSuccess}
              />
            )}
            {isFormSuccessfullySubmitted && (
              <Message message={form.successMessage}></Message>
            )}
          </div>
        )}

        {!!videoUrl && videoStyle === "modal" && (
          <VideoModal
            videoUrl={videoUrl}
            onClose={() => setIsVideoOpen(false)}
            isVideoOpen={isVideoOpen}
          />
        )}

        {!!mediaBackgroundImage && (
          <div
            className={clsx("SideBySide__BackgroundContainer", "w-full")}
            ref={ref}
            key={image.id}
          >
            <div className={clsx("SideBySide__imageWrapper")}>
              {mediaBackgroundImage?.extension === "svg" && (
                <SVG
                  src={
                    process.env.NODE_ENV === "development"
                      ? "/servd-volumes/images/" + image.path
                      : image.url
                  }
                  uniquifyIDs={true}
                  className={clsx("w-full h-auto")}
                  preProcessor={(code) =>
                    code
                      .replace(/font-family=".*?"/g, "")
                      .replace(
                        /fill="url/g,
                        'style="transform: translate(0.1px, 0.1px);shape-rendering: crispedges;" fill="url'
                      )
                  }
                />
              )}

              {!!mediaBackgroundImage?.url &&
                mediaBackgroundImage?.extension !== "svg" && (
                  <Image
                    key={mediaBackgroundImage.id}
                    src={mediaBackgroundImage.url}
                    width={mediaBackgroundImage.width}
                    height={mediaBackgroundImage.height}
                    alt={mediaBackgroundImage.title}
                    layout="responsive"
                    priority={isFirstPageSection ? true : false}
                    sizes={
                      width !== undefined
                        ? `${width < 100 ? 600 : Math.round(width)}px`
                        : "100vw"
                    }
                    placeholder={
                      mediaBackgroundImage.extension !== "svg" &&
                      mediaBackgroundImageBlur
                        ? "blur"
                        : "empty"
                    }
                    blurDataURL={
                      mediaBackgroundImageBlur
                        ? mediaBackgroundImageBlur.url
                        : null
                    }
                    className={clsx("SideBySide__image")}
                  />
                )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const Body = () => {
    return (
      <div
        className={clsx(
          "SideBySide__body",
          "lg:w-auto",
          "py-7.5 px-5 md:px-15",
          {
            //"pb-0": mobileMediaPosition === "above" && !hasBackgroundColor,
            //"pt-0": mobileMediaPosition === "below" && !hasBackgroundColor,
            "flex content-center flex-wrap": centerContent,
            "max-w-screen-half": fullWidth || wideContainer,
            "md:pr-7.5": mediaPosition === "right" && textPaddingMediaSide,
            "md:pl-7.5 2xl:pr-0":
              mediaPosition === "right" && !textPaddingMediaSide,
            "md:pl-7.5 2xl:pl-15":
              mediaPosition === "left" && textPaddingMediaSide,
            "md:pl-7.5 2xl:pl-0":
              mediaPosition === "left" && !textPaddingMediaSide,
            "lg:pt-15": textPaddingTop,
          }
        )}
      >
        {!!icon && (
          <div className={clsx("SideBySide__iconContainer", "mb-7.5")}>
            <Image
              key={icon.id}
              className={clsx("SideBySide__icon", "w-auto")}
              src={icon.url}
              width={icon.width}
              height={icon.height}
              alt={icon.title}
              placeholder={iconBlur ? "blur" : "empty"}
              blurDataURL={iconBlur ? iconBlur.url : null}
              layout="fixed"
            />
          </div>
        )}

        {heading && (
          <HeadingTag
            tag={headingTag}
            style={{ color: headingColor ? headingColor : textColor }}
            className={clsx(
              "SideBySide__heading",
              "max-w-xl",
              "mb-7.5 3xl:mb-7.5",
              {
                "text-4xl md:text-5xl": headingSize === "xxl",
                "text-3xl md:text-4xl": !headingSize || headingSize === "xl",
                "text-2xl md:text-3xl": headingSize === "lg",
                "text-2xl": headingSize === "md",
                "text-xl": headingSize === "sm",
              }
            )}
          >
            {ReactHtmlParser(formattedHeading)}
          </HeadingTag>
        )}

        <div
          className={clsx("SideBySide__textContainer", "relative", {
            "md:px-7.5": indentText,
          })}
        >
          {!!text && (
            <div
              className={clsx("SideBySide__text", "max-w-lg", "prose")}
              style={{ color: textColor }}
            >
              {ReactHtmlParser(text)}
            </div>
          )}

          <div className={clsx("SideBySide__targets", "lg:mb-0")}>
            {!!target && !!target.url && (
              <div className={clsx("SideBySide__target", "mt-7.5")}>
                <SecondaryButton
                  target={target}
                  legacyBackgroundColor={legacyButtonBackgroundColor}
                  textColor={buttonTextColor}
                />
              </div>
            )}

            {!!secondaryTarget && !!secondaryTarget.url && (
              <div className={clsx("SideBySide__secondaryTarget", "mt-4")}>
                <SecondaryButton
                  target={secondaryTarget}
                  legacyBackgroundColor={secondaryButtonBackgroundColor}
                  textColor={secondaryButtonTextColor}
                />
              </div>
            )}
          </div>
        </div>

        {showBackgroundGrid && (
          <div
            className={clsx(
              "SideBySide__backgroundGrid",
              "pointer-events-none",
              "mt-15 -ml-15",
              "absolute",
              "hidden xl:block"
            )}
          >
            <Image
              src={isBackgroundColorDark ? lightGrid : darkGrid}
              layout="fixed"
              alt="Grid"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={clsx("SideBySide", "relative overflow-hidden")}
      style={{
        color: textColor,
      }}
    >
      <div
        className={clsx(
          "SideBySide__content",
          "overflow-hidden",
          "mx-auto",
          { "pb-15": !!backgroundOverlay && mediaPaddingBottom },
          {
            "max-w-screen-xl": !fullWidth && !wideContainer,
            "max-w-screen-3xl": fullWidth,
            "max-w-screen-2xl": wideContainer,
          },
          "relative z-10",
          "flex flex-col lg:flex-row",
          "justify-center",
          {
            "flex-col-reverse lg:flex-row":
              mobileMediaPosition === "below" && mediaPosition === "right",
            "flex-col lg:flex-row-reverse": mediaPosition === "right",
          }
        )}
        style={{ backgroundColor: legacyBackgroundColor }}
      >
        <Media />
        <Body />
        <DotBorder />
      </div>

      {!!backgroundOverlay && (
        <div className="SideBySide__backgroundOverlay-wrapper max-w-screen-xl mx-auto relative hidden lg:block">
          <div
            className={clsx(
              "SideBySide__backgroundOverlay",
              "absolute bottom-0 z-0 text-[0px]",
              {
                "left-[-40%]": mediaPosition === "left",
                "right-[-40%]": mediaPosition === "right",
              }
            )}
          >
            <Image
              src={backgroundOverlay.url}
              layout="fixed"
              width={backgroundOverlay.width}
              height={backgroundOverlay.height}
              alt={backgroundOverlay.title}
            />
          </div>
        </div>
      )}

      {!!backgroundImage?.url && (
        <div
          className={clsx(
            "SideBySide__backgroundImage",
            "absolute inset-0 z-0",
            {
              "hidden lg:block": !!mobileBackgroundImage,
            }
          )}
        >
          <Image
            src={backgroundImage.url}
            layout="fill"
            objectFit="cover"
            alt={backgroundImage.title}
            draggable={false}
            {...getImageBlurOptions(backgroundImage, backgroundImageBlur)}
          />
        </div>
      )}

      {!!mobileBackgroundImage?.url && (
        <div
          className={clsx(
            "SideBySide__backgroundImage",
            "absolute inset-0 z-0 lg:hidden"
          )}
        >
          <Image
            src={mobileBackgroundImage.url}
            layout="fill"
            objectFit="cover"
            alt={mobileBackgroundImage.title}
            draggable={false}
            {...getImageBlurOptions(
              mobileBackgroundImage,
              mobileBackgroundImageBlur
            )}
          />
        </div>
      )}

      {videoStyle === "slideDown" && (
        <div
          className="SideBySide__slideDownVideo-section"
          ref={slideDownContainer}
        >
          <VideoSlideDown
            videoUrl={videoUrl}
            onClose={() => setIsVideoOpen(false)}
            isVideoOpen={isVideoOpen}
          />
        </div>
      )}
    </div>
  );
};

export default SideBySide;
