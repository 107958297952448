import clsx from "clsx";
import Image from "next/image";

import Target from "@components/Target";

const AwardsGrid = ({ awards }) => {
  return (
    <div
      className={clsx(
        "AwardsGrid",
        "px-7.5 lg:px-15",
        "max-w-screen-xl mx-auto"
      )}
    >
      <div
        className={clsx(
          "AwardsGrid__content",
          "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4",
          "border-l border-t"
        )}
      >
        {awards.map((award) => {
          const image = award.image[0];

          return (
            <Target
              key={award.id}
              target={award.target}
              className={clsx(
                "AwardsGrid__item",
                "aspect-w-1 aspect-h-1",
                "border-b border-r"
              )}
            >
              <div
                className={clsx(
                  "AwardsGrid__content",
                  "flex flex-col items-center justify-center"
                )}
              >
                <div
                  className={clsx(
                    "AwardsGrid__imageWrapper",
                    "relative",
                    "w-36 h-36",
                    "flex items-center justify-center",
                    "mb-4"
                  )}
                >
                  <Image
                    src={image.url}
                    layout="fill"
                    objectFit="contain"
                    alt={image.title}
                    className={clsx("AwardsGrid__image")}
                  />
                </div>
                <div
                  className={clsx("AwardsGrid__heading", "text-center", "px-4")}
                >
                  {award.heading}
                </div>
              </div>
            </Target>
          );
        })}
      </div>
    </div>
  );
};

export default AwardsGrid;
