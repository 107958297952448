import clsx from "clsx";
import Image from "next/image";
import dynamic from "next/dynamic";

import HeadingTag from "@components/HeadingTag";
import HighlightedString from "@components/HighlightedString";
import IconStrip from "@components/IconStrip";
import SecondaryButton from "@components/SecondaryButton";
import SubNavigation from "@components/SubNavigation";
import getImageBlurOptions from "@lib/getImageBlurOptions";

import { useAtomValue } from "jotai";
import { headerHeightAtom } from "@atoms/siteHeader";

import ReactHtmlParser from "react-html-parser";
import DotBorder from "@components/DotBorder";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

const Header = ({
	eyebrowHeading,
	heading,
	headingTag = "h2",
	headingSize = "lg",
	highlightColor,
	textAlignment = "center",
	subHeading,
	subHeadingSize = "lg",
	legacyBackgroundColor = "transparent",
	textColor = "currentColor",
	verticalPadding = "none",
	backgroundImage,
	backgroundOverlay,
	backgroundImageBlur,
	image,
	imageBlur,
	mobileImage,
	mobileImageBlur,
	isFirstPageSection = false,
	target,
	legacyButtonBackgroundColor,
	buttonTextColor,
	secondaryTarget,
	secondaryButtonTextColor,
	secondaryButtonBackgroundColor,
	videoUrl,
	entries = [], // for sub navigation
	iconStrip = [],
	maxWidth,
}) => {
	let formattedHeading;

	if (heading) {
		formattedHeading = heading.replace(/(?:\r\n|\r|\n)/g, "<br>");
	}

	const headerHeight = useAtomValue(headerHeightAtom);

	return (
		<div
			className={clsx("Header", "flex flex-col", "relative", "w-full")}
			style={{
				color: textColor,
			}}
		>
			<div
				className={clsx(
					"Header__content",
					"relative z-20",
					"px-5",
					"mx-auto w-full",
					{
						"py-7.5": verticalPadding === "sm",
						"py-7.5 lg:py-15": verticalPadding === "md",
						"py-15 lg:py-30": verticalPadding === "lg",
						"py-30 lg:py-60": verticalPadding === "xl",
						"max-w-screen-xl": !maxWidth || maxWidth === "max-w-screen-xl",
						"max-w-prose": maxWidth === "max-w-prose",
						"max-w-xs": maxWidth === "max-w-xs",
						"max-w-sm": maxWidth === "max-w-sm",
						"max-w-md": maxWidth === "max-w-md",
						"max-w-lg": maxWidth === "max-w-lg",
						"max-w-xl": maxWidth === "max-w-xl",
						"max-w-screen-half": maxWidth === "max-w-screen-half",
						"max-w-screen-sm": maxWidth === "max-w-screen-sm",
						"max-w-screen-md": maxWidth === "max-w-screen-md",
						"max-w-screen-lg": maxWidth === "max-w-screen-lg",
						"max-w-screen-2xl": maxWidth === "max-w-screen-2xl",
					}
				)}
				style={{
					marginTop:
						isFirstPageSection && headerHeight ? headerHeight + 32 : null,
					legacyBackgroundColor,
				}}
			>
				{!!entries && entries.length > 0 && (
					<div
						className={clsx("Header__subNavigation", "py-15", {
							"text-center": textAlignment === "center",
						})}
					>
						<SubNavigation entries={entries} />
					</div>
				)}
				{!!eyebrowHeading && (
					<div
						className={clsx(
							"Header__eyebrowHeading",
							"md:px-15",
							"mx-auto mb-4",
							"text-xl lg:text-2xl",
							{
								"max-w-screen-xl w-full lg:px-15": textAlignment === "left",
								"text-center max-w-5xl": textAlignment === "center",
							}
						)}
					>
						{eyebrowHeading}
					</div>
				)}
				{!!heading && (
					<HeadingTag
						tag={headingTag}
						className={clsx("Header__heading", "mx-auto", {
							"text-4xl md:text-6xl": headingSize === "xxxl",
							"text-4xl md:text-5xl": headingSize === "xxl",
							"text-3xl lg:text-4xl": !headingSize || headingSize === "xl",
							"text-2xl md:text-3xl lg:text-3xl": headingSize === "lg",
							"text-2xl": headingSize === "md",
							"text-xl": headingSize === "sm",
							"text-center max-w-5xl": textAlignment === "center",
						})}
					>
						<div
							className={clsx({
								"max-w-5xl": textAlignment === "left",
							})}
						>
							{ReactHtmlParser(formattedHeading)}
						</div>
					</HeadingTag>
				)}

				{!!subHeading && (
					<div
						className={clsx("Header__subHeading", "mx-auto", "pt-7.5", {
							//"text-xl lg:text-xl": subHeadingSize === "lg",
							//"text-base lg:text-lg": subHeadingSize === "sm",

							"text-4xl md:text-5xl": subHeadingSize === "xxxl",
							"text-3xl md:text-4xl": subHeadingSize === "xxl",
							"text-2xl lg:text-3xl":
								!subHeadingSize || subHeadingSize === "xl",
							"text-xl lg:text-xl": subHeadingSize === "lg",
							"text-lg lg:text-lg": subHeadingSize === "md",
							"text-base lg:text-lg": subHeadingSize === "sm",
							"max-w-screen-xl lg:px-15": textAlignment === "left",
							"text-center max-w-4xl": textAlignment === "center",
						})}
					>
						<div
							className={clsx({
								"max-w-5xl": textAlignment === "left",
							})}
						>
							<HighlightedString string={subHeading} color={highlightColor} />
						</div>
					</div>
				)}

				{!!image?.url && (
					<div
						className={clsx(
							"Header__imageWrapper",
							"max-w-screen-xl mx-auto pt-15"
						)}
					>
						<Image
							src={image.url}
							width={image.width}
							height={image.height}
							alt={image.title}
							className={clsx("Header__image")}
							{...getImageBlurOptions(image, imageBlur)}
						/>
					</div>
				)}

				{iconStrip.length > 0 && (
					<div className={clsx("Header__iconStrip", "mt-15")}>
						<IconStrip icons={iconStrip} />
					</div>
				)}

				{!!target && !!target.url && (
					<div
						className={clsx(
							"SideBySide__secondaryButton",
							"max-w-3xl",
							"text-center",
							"mt-10 mx-auto"
						)}
					>
						<SecondaryButton
							target={target}
							legacyBackgroundColor={legacyButtonBackgroundColor}
							textColor={buttonTextColor}
						/>
					</div>
				)}

				{!!secondaryTarget && !!secondaryTarget.url && (
					<div
						className={clsx(
							"SideBySide__secondaryTarget",
							"max-w-3xl",
							"text-center",
							"mt-4 mx-auto"
						)}
					>
						<SecondaryButton
							target={secondaryTarget}
							legacyBackgroundColor={secondaryButtonBackgroundColor}
							textColor={secondaryButtonTextColor}
						/>
					</div>
				)}

				<DotBorder />
			</div>

			{!!backgroundImage?.url && (
				<div
					className={clsx(
						"Header__backgroundImage",
						"absolute inset-0 z-10",
						"w-full h-full max-w-screen-3xl mx-auto"
					)}
				>
					<div>
						<Image
							src={backgroundImage.url}
							alt={backgroundImage.title}
							layout="fill"
							objectFit="cover"
							draggable={false}
							{...getImageBlurOptions(backgroundImage, backgroundImageBlur)}
						/>
					</div>
				</div>
			)}

			{!!videoUrl && (
				<div
					className={clsx(
						"VideoEmbed__inlineContainer",
						"absolute z-10 w-auto min-w-full min-h-full max-w-none overflow-hidden"
					)}
				>
					<ReactPlayer
						url={videoUrl}
						playsinline
						width="100%"
						height="unset"
						playing={true}
						muted={true}
						loop={true}
						config={{
							wistia: {
								options: {
									playerColor: "0D1418",
									controlsVisibleOnLoad: false,
									copyLinkAndThumbnailEnabled: false,
									playButton: false,
									wmode: "transparent",
									fitStrategy: "cover",
									chromeless: true,
									endVideoBehavior: "loop",
									classes: "z-11",
								},
							},
							file: {},
						}}
						className={clsx("VideoEmbed__player absolute z-10")}
					/>
				</div>
			)}

			{!!backgroundOverlay && (
				<div className="SideBySide__backgroundOverlay-wrapper w-full mx-auto relative hidden lg:block">
					<div
						className={clsx(
							"SideBySide__backgroundOverlay",
							"absolute bottom-0 left-0 z-0 text-[0px]"
						)}
					>
						<Image
							src={backgroundOverlay.url}
							layout="fixed"
							width={backgroundOverlay.width}
							height={backgroundOverlay.height}
							alt={backgroundOverlay.title}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default Header;
